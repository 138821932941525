import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

function auto_grow(e) {
    e.target.style.height = "inherit";
    e.target.style.height = (e.target.scrollHeight)+"px";
}

function get_url_indices(url) {
    let indices = [];
    let index = url.indexOf("http://");
    while (index !== -1) {
        indices.push(index);
        index = url.indexOf("http://", index + 1);
    }
    index = url.indexOf("https://");
    while (index !== -1) {
        indices.push(index);
        index = url.indexOf("https://", index + 1);
    }
    return indices;
}

function ContentBox(props) {
    let content = props.content;
    let indices = get_url_indices(content);
    if (indices.length === 0) {
        return content;
    }
    let result = [<span>{content.substring(0, indices[0])}</span>];
    let url = '';
    for (let i = 0; i < indices.length; i++) {
        let index = indices[i];
        url = content.substring(index, content.indexOf(' ', index) === -1 ? content.length : content.indexOf(' ', index));
        if (url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.gif') || url.endsWith('.jpeg') || url.endsWith('.bmp') || url.endsWith('.svg')) {
            result.push(<img src={url} alt={url}/>);
        } 
        else {
            result.push(<a href={url} target="_blank">{url}</a>);
        }
    }
    result.push(content.substring(indices[indices.length - 1] + url.length));
    return <p className="content">{result}</p>;
}

const postSorting = (a,b) => -a.upvotes + b.upvotes + a.downvotes - b.downvotes;

function App() {
  const [posts_loading, setPostsLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('https://socials.patrickc05.workers.dev/posts')
    .then(response => response.json())
    .then(data => {
      data.sort(postSorting);
      setPosts(data);
      setPostsLoading(false);
    })
    .catch(error => console.log(error));
  }, []);

  function createPost(e) {
    e.preventDefault();
    fetch('https://socials.patrickc05.workers.dev/posts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: e.target.title.value,
        username: e.target.username.value,
        content: e.target.content.value
      })
    })
    .then((response) => {
      if (response.status === 201) {
        let new_posts = [...posts, {'id': posts.length, 'upvotes': 0, 'downvotes': 0, 'title': e.target.title.value, 'username': e.target.username.value, 'content': e.target.content.value, 'comments': []}]
        new_posts.sort(postSorting);
        setPosts(new_posts);
        e.target.title.value = '';
        e.target.username.value = '';
        e.target.content.value = '';
        e.target.content.style.height = 'inherit';
      }
    })
    .catch(error => console.log(error));
  }

  function upvote(e) {
    let id = e.target.parentElement.parentElement.id.substring(5);
    let upvoted = e.target.classList.contains('upvote');
    fetch('https://socials.patrickc05.workers.dev/upvote', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'id': id,
          'upvote': upvoted
          })
    })
    .then(response => {
      if (response.status === 201) {
        let new_posts = [...posts];
        let index = new_posts.findIndex(post => post.id == id);
        if (upvoted) {
          new_posts[index].upvotes += 1;
        } 
        else {
          new_posts[index].downvotes += 1;
        }
        new_posts.sort(postSorting);
        setPosts(new_posts);
      }
    })
    .catch(error => console.log(error));
  }

  function addComment(e) {
    e.preventDefault();
    let id = e.target.parentElement.id.substring(5);
    fetch('https://socials.patrickc05.workers.dev/comment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'id': id,
        'username': e.target.username.value,
        'content': e.target.content.value
      })
    })
    .then(response => {
      if (response.status === 201) {
        let new_posts = [...posts];
        let index = new_posts.findIndex(post => post.id == id);
        new_posts[index].comments.push({'id': posts[index].comments.length, 'username': e.target.username.value, 'content': e.target.content.value});
        setPosts(new_posts);
        e.target.username.value = '';
        e.target.content.value = '';
        e.target.content.style.height = 'inherit';
      }
    })
    .catch(error => console.log(error));
  }

  if (posts_loading) {
    return <div>Loading...</div>;
  }
  if (posts.length === 0) {
    return <div>No posts</div>;
  }


  return (
    <main>
      <form className="post-container" onSubmit={createPost}>
        <input type="text" name="title" className="title" placeholder="Title" required/>
        <input type="text" name="username" className="username" placeholder="Username" required/>
        <textarea type="text" name="content" className="content" placeholder="Content" onInput={auto_grow} required/>
        <button type="submit">Post</button>
      </form>
      {posts.map(post => (
        <div key={post.id} className="post-container" id={`post-${post.id}`}>
          <h2 className="title">{post.title}</h2>
          <h3 className="username">{post.username}</h3>
          <ContentBox content={post.content}/>
          <p className="engage-container">{post.upvotes}<button className="engage upvote" onClick={upvote}>&#x25B2;</button>
          &emsp;{post.downvotes}<button className="engage downvote" onClick={upvote}>&#x25BC;</button>&emsp;
          <button className="engage" onClick={e => e.target.parentElement.nextSibling.hidden = !(e.target.parentElement.nextSibling.hidden)}>Comment</button></p>
          <form className="add-comment-container" onSubmit={addComment} hidden>
            <input type="text" name="username" className="username" placeholder="Username" required/>
            <textarea type="text" name="content" className="content" placeholder="Content" required/>
            <button type="submit">Add Comment</button>
          </form>
          
          {post.comments.length > 0 && <details className="comments-container">
            <summary>Show {post.comments.length} comments</summary>
            <div className="comments">
              {post.comments.map(comment => (
                <div key={comment.id} className="comment">
                  <h3 className="username">{comment.username}</h3>
                  <ContentBox content={comment.content}/>
                </div>
              ))}
            </div>
          </details>}
        </div>
      ))}
    </main>
  );
}

export default App;
